<template>
  <div>
    <a-calendar @panelChange="panelChange">
      <ul v-if="listKegiatan.length" slot="dateCellRender" slot-scope="value" class="events">
        <li class="mb-2" @click.prevent="handleSelectDate(item)" v-for="item in getListDataKegiatan(value)" :key="item.id">
          <a-tag color="#f6416c">
            {{item.content}}
          </a-tag>
        </li>
        <template v-if="listShift">
          <li class="mb-2" v-for="item in getListDataShift(value)" :key="item.key">
          <a-tag color="#28527a">
            <span>{{listShift.shift.nama}}</span><br>
            <span class="font-weight-bold">{{item.content}}</span>
          </a-tag>
        </li>
        </template>
        <template v-if="listKehadiran">
          <li class="mb-2" v-for="(item, idx) in getListDataPengajuan(value)" :key="idx">
          <a-tag :color="item.color">
            {{item.content}}
          </a-tag>
        </li>
        </template>
        <template v-if="listKehadiran">
          <li class="mb-2" v-for="(item, idx) in getListDataAbsensi(value)" :key="idx">
            <a-tag :color="item.color">
              {{item.content}}
            </a-tag>
          </li>
        </template>
      </ul>
      <template slot="monthCellRender" slot-scope="value">
        <div v-if="getMonthData(value)" class="notes-month">
          <section>{{ getMonthData(value) }}</section>
          <span>Backlog number</span>
        </div>
      </template>
    </a-calendar>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CalendarKegiatan',
  props: {
    listKegiatan: {
      type: Array,
      required: true,
    },
    listShift: {
      type: Object,
    },
    listKehadiran: {
      type: Object,
    },
    listPeriode: {
      type: Array,
    },
  },
  data() {
    return {
      datePanelSelected: null,
      periodActive: null,
    }
  },
  methods: {
    handleSelectDate(value) {
      this.$emit('selected-date', value)
    },
    getMonthData(value) {
      if (value.month() === 8) {
        return 1394
      }
    },
    panelChange(date, mode) {
      this.periodActive = this.listPeriode.filter(elPeriode => { moment(date).isBetween(moment(elPeriode.tanggal_awal), moment(elPeriode.tanggal_akhir), 'days', '[]') })
      console.log(this.listPeriode[0])
      console.log(moment(date).isBetween(moment(this.listPeriode[0].tanggal_awal), moment(this.listPeriode[0].tanggal_akhir)))
      this.datePanelSelected = date
    },
    isLibur(today, offDay) {
      if ((today.date() === offDay.date()) && (today.month() === offDay.month()) && (today.year() === offDay.year())) {
        return true
      }
      return false
    },
    isLiburPerulangan(today, offDay, recurringTo) {
      if ((today.date() === offDay.date()) && (today.month() === offDay.month()) && (today.year() >= offDay.year()) && (today.year() <= recurringTo)) {
        return true
      }
      return false
    },
    getListDataShift(value) {
      let listData
      if (!this.getListDataKegiatan(value).length) {
        this.listShift.shift.jadwal_shifts.forEach(elShift => {
          const hari = elShift.hari
          if (hari === value.locale('id').format('dddd')) {
            listData = [
              {
                content: `(${moment(elShift.jam_masuk, 'HH:mm').format('HH:mm')} - ${moment(elShift.jam_keluar, 'HH:mm').format('HH:mm')})`,
                key: elShift.id,
                ...elShift,
              },
            ]
          }
        })
      }
      return listData || []
    },
    getListDataPengajuan(value) {
      let listData
      if (!this.getListDataKegiatan(value).length) {
        const shift = this.getListDataShift(value)
        if (shift.length) {
          shift.forEach(elShift => {
            const hari = elShift.hari
            if (hari === value.locale('id').format('dddd')) {
              const findCuti = this.listKehadiran.cutiPegawai.filter(elCuti => value.isBetween(moment(elCuti.tanggal_awal), moment(elCuti.tanggal_akhir), 'days', '[]'))
              if (findCuti.length) {
                listData = [
                  {
                    color: '#fed049',
                    content: 'Cuti',
                  },
                ]
              }
              const findIzin = this.listKehadiran.izinPegawai.filter(elIzin => value.isBetween(moment(elIzin.tanggal_awal), moment(elIzin.tanggal_akhir), 'days', '[]'))
              if (findIzin.length) {
                listData = [
                  {
                    color: '#f48b29',
                    content: 'Izin',
                  },
                ]
              }
            }
          })
        }
      }
      return listData || []
    },
    getListDataAbsensi(value) {
      let listData
      if (!this.getListDataKegiatan(value).length) {
        const shift = this.getListDataShift(value)
        if (shift.length) {
          shift.forEach(elShift => {
            const hari = elShift.hari
            if (hari === value.locale('id').format('dddd')) {
              if (!this.getListDataPengajuan(value).length) {
                const findHadir = this.listKehadiran.absensi.filter(elAbsensi => value.format('YYYY-MM-DD') === elAbsensi.tanggal)
                if (findHadir.length) {
                  listData = [
                    {
                      color: '#00b8a9',
                      content: 'Hadir',
                    },
                  ]
                } else {
                  const activePeriod = this.listPeriode.filter(elPeriode => value.isBetween(moment(elPeriode.tanggal_awal), moment(elPeriode.tanggal_akhir), 'days', '[]'))[0]
                  if (activePeriod) {
                    if (value.isBefore(moment())) {
                      listData = [
                        {
                          color: '#810000',
                          content: 'Absen',
                        },
                      ]
                    }
                  }
                }
              }
            }
          })
        }
      }
      return listData || []
    },
    getListDataKegiatan(value) {
      let listData = []
      const findLibur = this.listKegiatan.filter(el => el.jenis === 'libur')
      const findAcara = this.listKegiatan.filter(el => el.jenis === 'acara')
      if (findLibur.length) {
        findLibur.forEach((elLibur, index) => {
          const tanggal = moment(elLibur.tanggal_awal)
          if (elLibur.status_pengulangan === 'active') {
            if (this.isLiburPerulangan(value, tanggal, elLibur.pengulangan_sampai)) {
              listData = [
                {
                  content: elLibur.nama,
                  key: elLibur.id,
                  ...elLibur,
                },
              ]
            }
          } else {
            if (this.isLibur(value, tanggal)) {
              // console.log('elLibur', elLibur)
              listData = [
                {
                  content: elLibur.nama,
                  key: elLibur.id,
                  ...elLibur,
                },
              ]
            }
          }
        })
      }

      if (findAcara.length) {
        findAcara.forEach(elAcara => {
          const tanggalAwal = moment(elAcara.tanggal_awal)
          const tanggalAkhir = moment(elAcara.tanggal_akhir)
          if (value.isBetween(tanggalAwal, tanggalAkhir, 'days', '[]')) {
            listData = [
              {
                content: elAcara.nama,
                key: elAcara.id,
                ...elAcara,
              },
            ]
          }
        })
      }
      return listData || []
    },
  },
}
</script>

<style lang="css">

/* {
  position: relative;
  margin-top: -11px;
} */

.ant-select.ant-select-enabled.ant-fullcalendar-year-select .ant-select-selection.ant-select-selection--single {
  height: 40px;
}

.ant-select.ant-select-enabled.ant-fullcalendar-month-select .ant-select-selection.ant-select-selection--single {
  height: 40px;
}

.ant-select.ant-select-enabled.ant-fullcalendar-year-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}

.ant-select.ant-select-enabled.ant-fullcalendar-month-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
  line-height: 38px;
}

.ant-radio-group.ant-radio-group-outline.ant-radio-group-default .ant-radio-button-wrapper.ant-radio-button-wrapper {
  height: 40px;
  display: none !important;
}

.ant-radio-group.ant-radio-group-outline.ant-radio-group-default .ant-radio-button-wrapper.ant-radio-button-wrapper span{
  line-height: 36px;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-header {
  text-align: left !important;
}

.ant-fullcalendar.ant-fullcalendar-full.ant-fullcalendar-fullscreen {
  margin-top: 20px;
}

.ant-fullcalendar.ant-fullcalendar-full.ant-fullcalendar-fullscreen .ant-fullcalendar-calendar-body {
  padding: 0;
}

.blackBorder {
  border: 1px solid black
}

</style>
